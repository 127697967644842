
import transformData from "@/utils/transformData";
import tableElement from './element.vue';

import { defineComponent, PropType } from "vue-demi";
import { mapGetters } from 'vuex';
import { QUESTIONNAIRE_STATUSES_ENUM, ROLES_ENUM } from '@/utils/enums';
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "ContainerBody",
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Array as PropType<Array<dynamicsObject>>,
      required: true,
    },
  },
  mounted() {
    this.followCheckbox();
  },
  computed: {
    ...mapGetters(['user']),
    roles() {
      return this.user && (this.user.role === ROLES_ENUM.SUPPORT || this.user.role === ROLES_ENUM.OWNER);
    }
  },
  components: {
    tableElement
  },
  methods: {
    transformData,
    modal(id: string, data = {}) {
      this.$store.commit("createModal", { id, data });
    },
    deleteAction(row: dynamicsObject) {
      return this.options.actions.find((a: string) => a === 'remove') && 
        (this.options.id !== 'questionnaire_student' || 
          (this.options.id === 'questionnaire_student' && (row.status === QUESTIONNAIRE_STATUSES_ENUM.NOTSENT || row.status === QUESTIONNAIRE_STATUSES_ENUM.NOTVERIFIED))
        ) &&
        (this.options.id !== 'students' || (this.options.id === 'students' && this.user && (this.user.role === ROLES_ENUM.OWNER || this.user.role === ROLES_ENUM.SUPPORT)));
    },
    followCheckbox() {
      const jquery = this.jq;
      const toggleExpertActive = this.toggleExpertActive;
      this.jq(".col input[type=\"checkbox\"]").change(function () {
        const active = jquery(this).prop("checked");
        const id = jquery(this).prop("id");
        toggleExpertActive(id, active);
      });
    },
    // async downloadFile(_id: string) {
    //   const result = await this.API.table.downloadQuestionnaireFile(`/api/questionnaire/file/${_id}`);
    //   const a = document.createElement('a');
    //   a.href = result.data.src;
    //   a.download = result.data.name;
    //   a.click();
    // },
    toggleExpertActive(id: string, active: boolean) {
      this.API.table.toggleExpertActive(`/api/user/active/${id}`, { active });
    },
  },
});
