import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-605860f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContainerHeader = _resolveComponent("ContainerHeader")!
  const _component_ContainerPreloadBody = _resolveComponent("ContainerPreloadBody")!
  const _component_ContainerBody = _resolveComponent("ContainerBody")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("table", null, [
      _createVNode(_component_ContainerHeader, {
        header: _ctx.options.header,
        query: _ctx.query,
        id: _ctx.options.id,
        onSelectedFilter: _ctx.selectedFilter,
        onSelectDateQuery: _ctx.selectDateQuery,
        onSelectTargetLength: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectTargetLength')))
      }, null, 8, ["header", "query", "id", "onSelectedFilter", "onSelectDateQuery"]),
      (_ctx.pending)
        ? (_openBlock(), _createBlock(_component_ContainerPreloadBody, {
            key: 0,
            variables: _ctx.options.variables
          }, null, 8, ["variables"]))
        : (_openBlock(), _createBlock(_component_ContainerBody, {
            key: 1,
            data: _ctx.data,
            options: _ctx.options
          }, null, 8, ["data", "options"]))
    ])
  ]))
}