import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container_header_element = _resolveComponent("container-header-element")!

  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (element) => {
        return (_openBlock(), _createBlock(_component_container_header_element, {
          key: element.id,
          element: element,
          query: _ctx.query,
          onSelectFilter: _ctx.selectFilter,
          onCancelFilter: _ctx.eventElementFilter,
          onSelectDateQuery: _ctx.selectDateQuery,
          onSelectElementFilter: _ctx.eventElementFilter,
          onSelectTargetLength: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectTargetLength')))
        }, null, 8, ["element", "query", "onSelectFilter", "onCancelFilter", "onSelectDateQuery", "onSelectElementFilter"]))
      }), 128))
    ])
  ]))
}