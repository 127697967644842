
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "TableSearch",
  emits: ["onSearch"],
  data() {
    return {
      search: "",
    };
  },
});
