import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-339e06fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table--header flex items-center justify-between" }
const _hoisted_2 = { class: "table--header-title" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableFilters = _resolveComponent("TableFilters")!
  const _component_TableSearch = _resolveComponent("TableSearch")!
  const _component_TableExcel = _resolveComponent("TableExcel")!
  const _component_TableActions = _resolveComponent("TableActions")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.options.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.options.id === 'questionnaire_notverified')
        ? (_openBlock(), _createBlock(_component_TableFilters, {
            key: 0,
            onOnFilters: _ctx.onFilters
          }, null, 8, ["onOnFilters"]))
        : _createCommentVNode("", true),
      (_ctx.options.id !== 'questionnaire_student')
        ? (_openBlock(), _createBlock(_component_TableSearch, {
            key: 1,
            onOnSearch: _ctx.onSearch
          }, null, 8, ["onOnSearch"]))
        : _createCommentVNode("", true),
      _createVNode(_component_TableExcel, { options: _ctx.options }, null, 8, ["options"]),
      _createVNode(_component_TableActions, {
        options: _ctx.options,
        onUpdateTable: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateTable')))
      }, null, 8, ["options"])
    ])
  ]))
}