
import { defineComponent } from "vue-demi";
import AirDatepicker from 'air-datepicker';

export default defineComponent({
  name: 'containerHeaderElement',
  emits: ['selectFilter', 'cancelFilter', 'selectTargetLength', 'selectElementFilter', 'selectDateQuery'],
  props: {
    element: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      datepicker: {} as AirDatepicker
    }
  },
  methods: {
    selectedDates(dates: Date[]) {
      this.datepicker.hide();
      this.$emit('selectDateQuery', { id: this.element.filters.collection, date: dates, element: this.element });
    }
  },
  mounted() {
    if (this.element.filters && this.element.filters.type === 'DATE') {
      this.datepicker = new AirDatepicker(`#${this.element.filters.collection}`, {
        range: true,
        multipleDates: true,
        toggleSelected: false,
        onSelect: ({ date }) => {
          if (Array.isArray(date) && date.length === 2) {
            this.selectedDates(date);
          }
        },
      });
    }
  },
})
