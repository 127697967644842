
import { defineComponent, PropType } from "vue-demi";

export default defineComponent({
  name: "ContainerBody",
  data() {
    return {
      number: 10,
    };
  },
  props: {
    variables: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
});
