import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e30034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table--header-search flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("img", {
      src: "/images/common/search.svg",
      alt: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSearch', _ctx.search)))
    }),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
      placeholder: "Поиск...",
      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('onSearch', _ctx.search)), ["enter"]))
    }, null, 544), [
      [_vModelText, _ctx.search]
    ])
  ]))
}