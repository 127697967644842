import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isFileStudentCourse)
    ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadStudentFile(_ctx._id)))
      }))
    : (_ctx.isFileStudent)
      ? (_openBlock(), _createElementBlock("td", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadStudentFile(_ctx.element.courseElement)))
        }))
      : (_ctx.isStudentEmail)
        ? (_openBlock(), _createElementBlock("td", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editStudent && _ctx.editStudent(...args)))
          }))
        : (_ctx.isFileQuestionnaire)
          ? (_openBlock(), _createElementBlock("td", {
              key: 3,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadQuestionnaireFile(_ctx._id)))
            }))
          : (_ctx.isFileExpert)
            ? (_openBlock(), _createElementBlock("td", {
                key: 4,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadExpertFile(_ctx._id)))
              }))
            : (_ctx.expertChangeRules)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 5,
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.changeExpert && _ctx.changeExpert(...args)))
                }))
              : (_ctx.streamDateRules)
                ? (_openBlock(), _createElementBlock("td", {
                    key: 6,
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.changeStreamDate && _ctx.changeStreamDate(...args)))
                  }))
                : (_openBlock(), _createElementBlock("td", {
                    key: 7,
                    "data-id": _ctx.element._id
                  }, null, 8, _hoisted_1))
}