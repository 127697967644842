
import { defineComponent } from "vue-demi";
import TableSearch from "./header/TableSearch.vue";
import TableActions from "./header/TableActions.vue";
import TableExcel from "./header/TableExcel.vue";
import TableFilters from "./header/TableFilters.vue";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "TableHeader",
  emits: ['onFilters', 'onSearch', 'updateTable'],
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSearch(search: string) {
      this.$emit("onSearch", search);
    },
    onFilters(query: dynamicsObject) {
      this.$emit("onFilters", query);
    },
  },
  components: {
    TableSearch,
    TableActions,
    TableExcel,
    TableFilters,
  },
});
