
import { defineComponent } from 'vue-demi';
import { mapGetters } from 'vuex';
import { Download, Upload, Plus, UserFilled, Files, Avatar, Calendar } from '@element-plus/icons';
import collectionData from '../../../../questionnaire/modules/collectionData';
import { COURSES_ENUM, ROLES_ENUM } from '@/utils/enums';
import { dynamicsObject } from '@/interfaces';

export default defineComponent({
  name: "TableActions",
  emits: ['updateTable'],
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      select: '',
      showActions: false
    }
  },
  computed: {
    ...mapGetters(['user', 'questionnaire']),
    access() {
      return this.user && (this.user.role === ROLES_ENUM.OWNER || this.user.role === ROLES_ENUM.SUPPORT || this.user.accesses.includes(ROLES_ENUM.EXPERT))
    }
  },
  unmounted() {
    this.emitter.off('selectQuestionnaireCourse', this.selectQuestionnaireCourseEmitter);
    this.emitter.off('questionnaireCreate', this.questionnaireCreateEmitter);
  },
  mounted() {
    this.emitter.on('questionnaireCreate', this.questionnaireCreateEmitter);
    this.emitter.on('selectQuestionnaireCourse', this.selectQuestionnaireCourseEmitter);
  },
  methods: {
    modal(id: string, data = {}) {
      this.$store.commit('createModal', { id, data });
    },
    createQuestionnaire() {
      if (this.user && this.user.courses) {
        this.$store.commit('createModal', { id: 'questionaire-select-course', data: {} });
      } else {
        this.generateQuestionnaire(true);
      }
    },
    async selectQuestionnaireCourseEmitter(data: dynamicsObject) {
      await this.$store.commit('updateUser', data);
      this.generateQuestionnaire(true);
    },
    questionnaireCreateEmitter() {
      this.generateQuestionnaire(false);
    },
    async generateQuestionnaire(showInfo: boolean) {
      if (showInfo && this.user.course.type === COURSES_ENUM.TWO && this.user.role === ROLES_ENUM.STUDENT) {
        this.$store.commit('createModal', { id: 'questionnaire-create-info', data: {} });
        return;
      } 
      await this.$store.commit('clearQuestionnaire', { course: this.user.course.type, role: this.user.role, owner: this.user.role });
      const data: dynamicsObject = {};
      data[`content_${this.user.role}`] = collectionData(this.questionnaire);
      const result = await this.API.questionnaire.onGenerateQuestionnaire(data);
      this.$router.push(`/questionnaire/${result.data._id}`);
    },
    async downloadStudents() {
      this.$store.commit("createNotification", { status: "success", message: 'Скачивание списка началось!' });
      const query: dynamicsObject = {};
      const array = this.options.header.filter((h: dynamicsObject) => h.filters?.filter).map((h: dynamicsObject) => ({ id: h.id, value: h.filters?.filter }));
      for (const element of array) {
        query[element.id] = element.value;
      }

      const result = await this.API.table.onDownloadStudents(query);
      const href = (window.URL || window.webkitURL).createObjectURL(result);
      const a = document.createElement("a");
      a.href = href;
      a.download = `Список студентов.xlsx`;
      a.click();
    },
  },
  components: {
    Download, Upload, Plus, UserFilled, Files, Avatar, Calendar
  }
});
