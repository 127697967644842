import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6f76504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = { class: "table--body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        options: _ctx.options,
        onOnSearch: _ctx.onSearch,
        onUpdateTable: _ctx.getData,
        onOnFilters: _ctx.onFilters
      }, null, 8, ["options", "onOnSearch", "onUpdateTable", "onOnFilters"]),
      _createVNode(_component_Container, {
        pending: _ctx.pending,
        options: _ctx.options,
        data: _ctx.data,
        query: _ctx.query,
        onSelectDateQuery: _ctx.selectDateQuery,
        onSelectedFilter: _ctx.selectedFilter,
        onSelectTargetLength: _ctx.selectTargetLength
      }, null, 8, ["pending", "options", "data", "query", "onSelectDateQuery", "onSelectedFilter", "onSelectTargetLength"])
    ]),
    (_ctx.total > _ctx.query.limit)
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 0,
          onSelectPage: _ctx.selectPage,
          total: _ctx.total,
          query: _ctx.query
        }, null, 8, ["onSelectPage", "total", "query"]))
      : _createCommentVNode("", true)
  ]))
}