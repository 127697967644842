import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76f42453"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "btn btn--green btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.options.export && !_ctx.pendingRequest)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn--green",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onExport && _ctx.onExport(...args)))
        }, " xlsx "))
      : _createCommentVNode("", true),
    (_ctx.options.export && _ctx.pendingRequest)
      ? (_openBlock(), _createElementBlock("button", _hoisted_1, " xlsx "))
      : _createCommentVNode("", true)
  ], 64))
}