
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { Filter } from '@element-plus/icons';
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "TableFilters",
  emits: ['onFilters'],
  data() {
    return {
      showMenu: false,
      filters: [
        { id: 'civilServant', name: 'Госслужащий', list: [
          { _id: 1, name: 'Да', selected: false },
          { _id: 2, name: 'Нет', selected: false },
          { _id: 3, name: 'Нерезидент РФ', selected: false },
        ] },
        { id: 'risk', name: 'Риск-профиль', list: [
          { _id: 'careful', name: 'Осторожный', selected: false },
          { _id: 'conservative', name: 'Консервативный', selected: false },
          { _id: 'moderate', name: 'Умеренный', selected: false },
          { _id: 'aggressive', name: 'Агрессивный', selected: false },
          { _id: 'stocks', name: 'Акции 100%', selected: false },
        ] },
        { id: 'term', name: 'Срок цели', list: [
          { _id: 1, name: 'макс/мин', selected: false },
          { _id: -1, name: 'мин/макс', selected: false },
        ] },
      ]
    }
  },
  methods: {
    selectFilter(filter: dynamicsObject, element: dynamicsObject) {
      for (const el of filter.list) {
        if (el._id !== element._id) el.selected = false;
      }
      element.selected = !element.selected;
    },
    onFilters() {
      const query: dynamicsObject = {civilServant: '', risk: '', term: ''};
      this.filters.forEach(filter => {
        filter.list.forEach((element: dynamicsObject) => {
          if (element.selected) query[filter.id] = element._id;
        })
      });
      this.$emit('onFilters', query);
    }
  },
  computed: mapGetters(["pendingRequest"]),
  components: {
    Filter
  }
});
